import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AppLayoutComponent } from './layout/app.layout.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [MsalGuard],
        children: [
            {
              path: 'configuration',
              data: { breadcrumb: 'Configuration' },
              loadChildren: () => import('./support/components/configuration/configuration.module').then(m => m.ConfigurationModule)
            },
            {
              // Needed for handling redirect after login
              path: 'auth',
              component: MsalRedirectComponent
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
