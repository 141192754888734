import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TokenManagerService } from '../support/service/tokenmanager.service'


/**
 * The AWS request token interceptor class that adds additional headers for the business role.
 */
@Injectable()
export class AwsTokenInterceptor implements HttpInterceptor {

  constructor(private tokenManager: TokenManagerService) { }

  /**
 * Intercepts HTTP requests and adds an access token to requests that include 'frontend' in the URL.
 * @summary Adds an access token to requests that include 'frontend' in the URL and logs the request and response.
 * @param {HttpRequest<any>} request - The HTTP request to intercept.
 * @param {HttpHandler} next - The next HTTP handler in the chain.
 * @returns {Observable<HttpEvent<any>>} An observable of the HTTP event.
 */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.toLowerCase().includes('support') || request.url.toLowerCase().includes('onboarding')) {
      return this.tokenManager.getAccessToken().pipe(
        switchMap(accessToken => {
          if (accessToken) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${accessToken}`,
                'x-handled-by-aws': 'true',
              }
            });
          }
          return next.handle(request);
        })
      );
    }
    return next.handle(request);
  }
}
