import { Component, ElementRef, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { protectedResources } from '../auth-config';
import { TokenManagerService } from '../support/service/tokenmanager.service';

const GRAPH_ENDPOINT = protectedResources['graphApi'].endpoint;

export interface ProfileType  {
  businessPhones: any[]
  displayName: string
  givenName: any
  jobTitle: string
  mail: string
  mobilePhone: any
  officeLocation: any
  preferredLanguage: string
  surname: any
  userPrincipalName: string
  id: string
};

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.css']
})
export class AppTopbarComponent implements OnInit {

  profile!: ProfileType;
  isAdmin: boolean = false; // add isAdmin property

  // logout cick
  @Output() logoutClick = new EventEmitter<void>();
  loggedInUser: string = '';

  onLogoutClick() {
    this.logoutClick.emit();
  }

  @ViewChild('menuButton') menuButton!: ElementRef;

  @ViewChild('mobileMenuButton') mobileMenuButton!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private http: HttpClient,
    private msalService: MsalService,
    private tokenManagerService: TokenManagerService) { }


  activeItem!: number;

  get mobileTopbarActive(): boolean {
    return this.layoutService.state.topbarMenuActive;
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onMobileTopbarMenuButtonClick() {
    this.layoutService.onTopbarMenuToggle();
  }

  async ngOnInit() {
    this.getProfile();
    this.checkUserRole();
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile as ProfileType;
        this.loggedInUser = this.profile.displayName;
      });
  }

  checkUserRole() {
    const roleName = 'supportadmin';
    const resourceName = 'authToken';

    this.tokenManagerService.token$.subscribe(token => {
    });

    this.tokenManagerService.hasRole(roleName, resourceName).subscribe(hasRole => {
      this.isAdmin = hasRole;
    });
  }

}
