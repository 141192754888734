import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { ITableService } from 'src/app/common/interfaces/table/table-service.interface';
@Injectable({
  providedIn: 'root',
})
export class ApiClientEnvironmentKeyService implements ITableService {
  controller = `/support/apiclientenvironmentkeys`;
  urlGet = `${environment.apiUrl}${this.controller}/grid`;
  urlGetExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;
  private apiClientEnvironmentKeysJson = 'assets/Mocks/apiClientEnvironmentKeys/apiClientEnvironmentKeys.json'

  constructor(private http: HttpClient) { }

  tableFilter(filter: ITableServerFilter): Observable<ITableServerResponseFilter> {
    return this.http.get<ITableServerResponseFilter>(this.apiClientEnvironmentKeysJson);
  }
  getData(): Observable<ITableServerResponseFilter> {
    return this.http.get<ITableServerResponseFilter>(this.apiClientEnvironmentKeysJson);
  }
  tableExportCSV(filter: ITableServerFilter): Observable<Blob> {
    return this.http.post(`${this.urlGetExportCSV}`, filter, { responseType: 'blob' });
  }
}
