import { Provider } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AbstractApiClientEnvironmentKeyService } from 'src/app/support/service/abstraction/abstract.apiclient-environment-key';
import { MockApiClientEnvironmentKeyService } from 'src/app/support/service/mock/mock.apiclient-environment-key';
import { ApiClientEnvironmentKeyService } from 'src/app/support/service/configuration/apiclient-environment-key.service';

export const serviceProviders: Provider[] = [

  {
    provide: AbstractApiClientEnvironmentKeyService,
    useClass: environment.useMock ? MockApiClientEnvironmentKeyService : ApiClientEnvironmentKeyService,
  },

];
